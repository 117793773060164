import React from "react";
import "./box.css";
// import { Card } from "react-bootstrap";
import { Row, Col, Container } from 'react-bootstrap';
import img1 from '../asset/img/Manish.png';
import img2 from '../asset/img/sangeeta.png';
import img3 from '../asset/img/abhijeet.jpg';
// import img4 from '../asset/img/gursahi.jpg';
import img5 from '../asset/img/Sunita.jpg';
// import img6 from '../asset/img/dviya.jpg'
import img7 from '../asset/img/ajay.jpg'
// import img8 from '../asset/img/worker3.jpeg'
import img10 from '../asset/img/Jasmeen.jpg'
import img11 from '../asset/img/aryan.jpg'
import img12 from '../asset/img/Anurag.jpg'
// import img13 from '../asset/img/worker8.jpeg'
// import img14 from '../asset/img/worker9.jpeg'
// import img15 from '../asset/img/neha.jpg'
import img16 from '../asset/img/yogesh-arya.jpg'
import img17 from '../asset/img/Akash.jpg'
import img18 from '../asset/img/vivek.jpg'
import img19 from '../asset/img/Tanya.jpg'
import img20 from '../asset/img/daksh.jpg'
import img21 from '../asset/img/jatin-bhardwaj.JPG'
import img22 from '../asset/img/Animesh.jpg'
import img23 from '../asset/img/karuna-sharma.jpg'
import img24 from '../asset/img/Annu.png'
import img25 from '../asset/img/komal.jpg'
import img26 from '../asset/img/laxmi.jpg'
import img27 from '../asset/img/monika.JPG'
import img29 from '../asset/img/nikhil.jpg'
import img30 from '../asset/img/rinki.jpg'
import img32 from '../asset/img/rinky.jpg'
import img33 from '../asset/img/rishabh.jpg'
import img34 from '../asset/img/sohan.jpg'
import img35 from '../asset/img/Raj.jpg'
import img36 from '../asset/img/Sakshi.jpg'
import img37 from '../asset/img/somya-singh.jpg'
import img38 from '../asset/img/Tarun.JPG'
import img39 from '../asset/img/AnkushSharma.png'
import img40 from '../asset/img/SidharthSharma.png'
import img41 from '../asset/img/GoutamDagar.png'
import img42 from '../asset/img/NikhilGahlot.png'
// import img1 from '../asset/img/Manish-Verma.jpg';
const MoreDeets = () => {
  const image = require.context('../../src/asset/img', true);
  const cardInfo = [

    {
      image:
        img1,
      title: "MANISH VERMA",
      text: "Keen, logical, observant, and ever learner - Manish ensures that the team keeps its focus on broader vision while completing its daily tasks in a process-oriented way. With senior management experience of over 14 years with likes of ICICI, Reliance & Fullerton, Manish backs up the team fully to achieve growth.",
    },

    {
      image:
        img2,
      title: "SANGEETA VERMA",
      text: "What’s need to be done needs to be done. It’s better when a woman does it. Starting from scratch she has built a successful Digital Marketing agency in 6 years. She is very sound technically on various aspects of Digital Marketing",
    },
   
    // {
    //   image:
    //     img4,
    //   title: "GURSAHIB SINGH",
    //   text: "Part Time Graphic Designer, Full Time Creator!",
    // },
    {
      image:
        img5,
      title: "SUNITA TENIA",
      text: "She's always ready for work!!! As a digital marketing administrator, she is responsible for overseeing the digital presence and promotions of businesses, companies and different organizations.",
    },
    // {
    //   image:
    //     img6,
    //   title: "DIVYA AGGARWAL",
    //   text: "Experienced Digital Marketer with a measurable track record of creating successful online strategic marketing campaigns, resulting in enhanced brand positioning and growth.",
    // },
    {
      image:
        img7,
      title: "AJAY",
      text: "Website Developer - I bring a strong technical background along with the ability to manage my time effectively. This allows me to tackle new projects quickly, adapt to changing priorities, and think critically to find innovative solutions.",
    },
    // {
    //   image:
    //     img8,
    //   title: "BHAVYA PAMBRI",
    //   text: "She's Bhavya! She'll help you with Facebook & Instagram Ads. Looking forward to discussing Strategies, campaigns and Obviously, MIND-BLOWING Results.",
    // },

    {
      image:
        img10,
      title: "JASMEEN KAUR",
      text: "I am here to put everything out on display! I post creatives, videos, and all the content on social media profiles of the clients. Without a proper management of the design and content buffer, there would be no posting on social media handles of the clients.",
    },
    // {
    //   image:
    //     img11,
    //   title: "ARYAN SINGH",
    //   text: "Social Media Strategist:- I believe in the power of collaboration. My creative energy thrives alongside other passionate minds, and together we develop winning social media strategies that drive results. Adaptability is the key.",
    // },
    {
      image:
        img12,
      title: "ANURAG",
      text: "The only line that elaborate me I believe in evidence. I believe in observation, measurment, and reasoning",
    },
    // {
    //   image:
    //     img13,
    //   title: "HEMLATA",
    //   text: "There's a lot that the eyes miss, but that's not the case with her. Efficiently saving costs & time for the company, she monitors the logistics & supports the eCommerce division of the company.",
    // },
    // {
    //   image:
    //     img14,
    //   title: "HEMA",
    //   text: "With 8+ years of experience in managing multi product, multi platform eCommerce sales, operations & logistics - I brings with her all the expertise to launch & grow a brand successfully through e-Commerce.",
    // },
    // {
    //   image:
    //     img15,
    //   title: "NEHA",
    //   text: "I am a BCA graduate and also done diploma in Multimedia & Animation. I am quite good in photo and video editing. Creative and keen learner. Believe in solving problem instead of cribbing about it. As an Optimist, I always believe tomorrow will be better than today.",
    // },
    // {
    //   image:
    //     img16,
    //   title: "PALLAVI ROHATGI GUPTA",
    //   text: "A creative head who is a writer in the day and a reader at the night.",
    // },
    {
      image:
        img17,
      title: "AKASH RATHORE",
      text: "I am talented, ambitious and hardworking. Further, my creative mind provides solutions when working under pressure, critical deadlines and challenging situations.",
    },
    // 
    // {
    //   image:
    //     img19,
    //   title: "TANYA GOEL",
    //   text: "As a content writer I like playing with words. I am responsible for styling and formatting consistency across all projects and communicating with other team members to create the best content possible. Other duties and responsibilities include, Working with customers to define their content needs.",
    // },
    {
      image:
        img20,
      title: "DAKSH KAKKAR",
      text: "Social Media Strategist & Content Writer:- I don't just craft engaging content, I develop winning social media plans. I love using my communication skills to turn ideas into reality and, all while keeping a close eye on results and time management.",
    },

    // new 

    // {
    //   image:
    //     img21,
    //   title: "JATIN BHARDWAJ",
    //   text: "Performance Marketer and Social Media Account Manager - I can analyze situations from all angles and come up with creative solutions. I'm also a great communicator, so I can turn those ideas into clear plans and keep everyone moving in the right direction.",
    // },
    {
      image:
        img22,
      title: "ANIMESH",
      text: "Video Editor - I love crafting content into a compelling story through editing and visual magic. From color correction to perfect audio sync, I ensure every element is meticulously polished for a professional finish.",
    },
    // {
    //   image:
    //     img23,
    //   title: "KARUNA",
    //   text: "Graphic Designer - I find solutions that keep the project moving forward. I collaborate seamlessly with everyone involved, fostering clear communication for a smooth workflow.",
    // },
    // {
    //   image:
    //     img24,
    //   title: "ANNU",
    //   text: "Graphic Designer - I manage my time well, ensuring projects are completed on time and often exceed expectations. I'm adaptable, always eager to learn new skills and stay updated with the latest editing trends.",
    // },
    {
      image:
        img25,
      title: "KOMAL",
      text: "Social Media Ads Manager -  I thrive in changing environments, readily adjusting my approach to tackle new situations and stay ahead of the curve. I'm comfortable navigating new tools and platforms, always finding a way to overcome challenges with a tech-forward approach",
    },
    {
      image:
        img26,
      title: "LAXMI",
      text: "Customer Support Executive - Setbacks are opportunities for growth, not defeat. I've learned to bounce back stronger from challenges. I approach difficulties with a problem-solving mindset.",
    },
    {
      image:
        img27,
      title: "MONIKA",
      text: "Senior Customer Support Executive - I thrive on setting goals and working independently to achieve them. Internal drive is my primary fuel for success.",
    },
    // {
    //   image:
    //     img28,
    //   title: "Jatin",
    //   text: "",
    // },
    {
      image:
        img29,
      title: "NIKHIL",
      text: "E-Commerce Executive - I'm naturally drawn to breaking down complex problems into manageable components. I enjoy the challenge of piecing together information to form a clear understanding.",
    },
    // {
    //   image:
    //     img30,
    //   title: "RINKI",
    //   text: "Account Assistant:- Backed by strong analytical skills and a detail-oriented mindset, I can identify and solve financial discrepancies.",
    // },
    {
      image:
        img32,
      title: "RINKY",
      text: "Content Writer - I can craft compelling narratives that capture people's attention and leave a lasting impression. I have a keen eye for catching mistakes, ensuring everything is polished and perfect before it goes out the door. ",
    },
    {
      image:
        img33,
      title: "RISHABH",
      text: "Account Assistant:- I ensure all financial accounts are managed with precision. I excel at data analysis and financial calculations, combined with my technical expertise",
    },
    {
      image:
        img34,
      title: "SOHAN",
      text: "Digital Marketing Executive - I'm a quick problem-solver who can handle unexpected issues calmly and efficiently, always finding a solution. I can clearly explain complex ideas and share information in a way that's easy to understand for everyone involved.",
    },
    {
      image:
        img35,
      title: "RAJ",
      text: "Seo and Google Ads Manager - I am a Data Ninja, Strategist with an Artist's Touch. I'm always learning and adjusting my approach to stay ahead of the curve and solve any challenges that arise.",
    },
    {
      image:
        img36,
      title: "SAKSHI",
      text: "Social Media Manager -  thrive in environments that challenge me to think outside the box. My strong communication skills allow me to effectively translate these creative ideas into clear and actionable plans.",
    },
    {
      image:
        img37,
      title: "SOMYA",
      text: "E-Commerce Executive:- Combining strong organizational skills with a knack for creative solutions, I tackle challenges head-on, ensuring efficient task management and exceeding sales targets.",
    },
    {
      image:
        img38,
      title: "TARUN",
      text: "Senior Accountant - I possess a keen eye for detail, ensuring accuracy in everything I do. This meticulous approach allows me to consistently deliver high-quality work.",
    },
    {
      image:
        img19,
      title: "TANYA",
      text: "HR Generalists:- I can explain things clearly, listen without judgment, and find creative solutions. Whether it's writing a job description or helping someone through a tough situation, I make sure everyone feels heard and understood.",
    },
    {
      image:
        img18,
      title: "VIVEK",
      text: "Customer Support Executive:- Whether it's someone needing help with techie stuff or a customer who's a bit worked up, I can find the perfect way to explain things and get them back on track.",
    },
    {
      image:
        img16,
      title: "YOGESH",
      text: "E-Commerce Executive:- I leverage my analytical mind and technical proficiency to translate data insights into actionable strategies, consistently driving growth in the e-commerce landscape.",
    },
    {
      image:
        img39,
      title: "Ankush Sharma",
      text: "Performance Marketer - I can analyze situations from all angles and come up with creative solutions. I'm also a great communicator, so I can turn those ideas into clear plans and keep everyone moving in the right direction.",
    },
    {
      image:
        img40,
      title: "Sidharth Sharma",
      text: "Video Editor - I meticulously work through footage, knowing the extra attention leads to stunning results. I believe visuals and sound can weave narratives that captivate audiences.",
    },
    {
      image:
        img41,
      title: "Goutam Dagar",
      text: "Graphic Designer -  I manage my time effectively, ensuring projects are delivered on time and exceed expectations. I'm adaptable, always ready to learn new skills and embrace the latest editing trends.",
    },
    {
      image:
        img42,
      title: "Nikhil Gahlot",
      text: "Graphic Designer and Video Editor - I find solutions that keep the project moving forward. I collaborate seamlessly with everyone involved, fostering clear communication for a smooth workflow.",
    },
  ];

  const renderCard = (card, index) => {
    return (
      <div >
        <Container>
          <Row>
            <Col  >
              <div className='about-sec-img'>
                <img
                  className="d-block center"
                  width="100%"
                  height=""
                  src={card.image}
                  alt="Client1"
                />
                {/* <img src="" alt="Avatar" > */}
                <div className="overlay">
                  <div className="text">
                    <h1>{card.title}</h1>
                    <p>{card.text}</p>
                  </div>
                </div>
              </div>

            </Col>
          </Row>
        </Container>
        {/* // <Card style={{ width: "18rem" }} key={index} className="box">
      //   <Card.Img variant="top" src="holder.js/100px180" src={card.image} />
      //   <Card.Body>
      //     <Card.Title>{card.title}</Card.Title>
      //     <Card.Text>{card.text}</Card.Text>
      //   </Card.Body>
      // </Card> */}
      </div>
    );
  };

  return <div className="grid">{cardInfo.map(renderCard)}</div>;
};

export default MoreDeets;