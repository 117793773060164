import React from 'react'
import Caro1 from '../asset/img/services/delievers1.jpg'
import Caro2 from '../asset/img/services/delievers2.jpeg'
import Caro3 from '../asset/img/services/delievers3.jpeg'
import Caro4 from '../asset/img/services/delievers4.jpeg'
import Caro5 from '../asset/img/services/delievers5.png'
import Caro6 from '../asset/img/services/delievers6.png'
import Caro7 from '../asset/img/services/delievers7.png'
import Caro8 from '../asset/img/services/delievers8.png'
import Caro9 from '../asset/img/services/delievers9.png'
import Caro10 from '../asset/img/services/delievers10.jpg'
import Caro11 from '../asset/img/services/delievers11.png'
import Caro12 from '../asset/img/services/delievers12.jpeg'
import Caro13 from '../asset/img/services/delievers13.png'
import Caro14 from '../asset/img/services/delievers14.png'
import Caro15 from '../asset/img/services/delievers15.jpeg'
import Caro16 from '../asset/img/services/delievers16.jpg'
import Caro17 from '../asset/img/services/delievers17.jpeg'
import Caro18 from '../asset/img/services/delievers18.jpeg'
import Caro19 from '../asset/img/services/delievers19.jpeg'
import Caro20 from '../asset/img/services/delievers20.jpeg'
import Caro21 from '../asset/img/services/delievers21.jpeg'
import Caro22 from '../asset/img/services/delievers22.jpeg'
import Caro23 from '../asset/img/services/delievers23.jpeg'
import { Row, Col, Container, Figure, Card } from 'react-bootstrap';
import './carousel1.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";;

const carousel1 = () => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    return (
        <div>

            <Container>

                <Row>
                    <Col className="text-center heading-full-growth">
                        <h1>
                            DIGIDZN DELIVERS
                        </h1>
                    </Col>
                </Row>

                <div className="mt-2 mb-5 pl-3 py-3 pr-0 cursor-point">
                    <Carousel
                        swipeable={false}
                        draggable={true}
                        responsive={responsive}
                        infinite={true}
                        autoPlay={true}
                        customTransition="transform 3000ms ease-in-out"
                        transitionDuration={3000}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        partialVisible={true}
                        arrows={false}
                        autoPlaySpeed={3000}
                        showDots={false}
                        focusOnSelect={false}
                    >
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro17}
                                    alt="Caro17"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro18}
                                    alt="Caro18"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro19}
                                    alt="Caro19"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro20}
                                    alt="Caro20"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro21}
                                    alt="Caro21"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro22}
                                    alt="Caro22"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro23}
                                    alt="Caro23"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro1}
                                    alt="Caro1"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro2}
                                    alt="Caro2"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro3}
                                    alt="Caro3"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro4}
                                    alt="Caro4"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro5}
                                    alt="Caro5"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro6}
                                    alt="Caro6"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro7}
                                    alt="Caro7"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro8}
                                    alt="Caro8"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro9}
                                    alt="Caro9"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro10}
                                    alt="Caro10"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro11}
                                    alt="Caro11"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro12}
                                    alt="Caro12"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro13}
                                    alt="Caro13"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro14}
                                    alt="Caro14"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro15}
                                    alt="Caro15"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="icon-1 mb-4">
                                <img
                                    className="d-block img-icon"
                                    src={Caro16}
                                    alt="Caro16"
                                />
                            </div>
                        </div>

                    </Carousel>
                </div>
            </Container>
        </div>
    )
}

export default carousel1