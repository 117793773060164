import React from 'react'

import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../Components/Footer';
import { Helmet } from 'react-helmet-async';
import line from '../asset/img/line.svg'
import casestudy from '../asset/img/case.jpg'

import d1 from '../asset/img/deliveries/d1.png'
import d2 from '../asset/img/deliveries/d2.png'
import d3 from '../asset/img/deliveries/d3.png'
import d4 from '../asset/img/deliveries/d4.jpeg'
import d5 from '../asset/img/deliveries/d5.png'
import d6 from '../asset/img/deliveries/d6.png'
import d7 from '../asset/img/deliveries/d7.jpeg'
import d8 from '../asset/img/deliveries/d8.jpeg'
import d9 from '../asset/img/deliveries/d9.png'
import d10 from '../asset/img/deliveries/d10.png'
import d11 from '../asset/img/deliveries/d11.png'
import d12 from '../asset/img/deliveries/d12.png'
import d13 from '../asset/img/deliveries/d13.png'
import d14 from '../asset/img/deliveries/d14.jpg'
import d15 from '../asset/img/deliveries/d15.png'
import d16 from '../asset/img/deliveries/d16.jpeg'
import d17 from '../asset/img/deliveries/d17.png'
import d18 from '../asset/img/deliveries/d18.png'
import d19 from '../asset/img/deliveries/d19.jpeg'
import d20 from '../asset/img/deliveries/d20.jpg'
import d21 from '../asset/img/deliveries/d21.jpg'
import d22 from '../asset/img/deliveries/d22.jpg'
import d23 from '../asset/img/deliveries/d23.jpeg'
import d24 from '../asset/img/deliveries/d24.jpeg'
import d25 from '../asset/img/deliveries/d25.jpeg'
import d26 from '../asset/img/deliveries/d26.jpeg'
import d27 from '../asset/img/deliveries/d27.jpeg'
import d28 from '../asset/img/deliveries/d28.jpeg'
import d29 from '../asset/img/deliveries/d29.jpeg'

function Results() {


    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoPlay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const client = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div>
            <Helmet>
                <title>Digital Marketing services company in Delhi | Digital Marketer | digiDZN</title>
                <meta name="description" content="India's #1 Digital Marketing Services. digiDZN offers a broad range of digital marketing services in Delhi including branding and strategy, social media marketing, Pay-per-click, Display advertising, and more." />
            </Helmet>

            {/* <div className="section3-carousel">
                <Container>
                    <Row className='pt-5'>
                        <Col xs={12} className="text-center heading-full-growth pt-3 pb-3"><h1>CASE STUDY</h1></Col>
                        <Col md={5} xs={12} className='mb-3' >
                            <img
                                className="d-block img-icon w-100"
                                src={casestudy}
                                alt="casestudy"
                            />
                        </Col>
                    </Row>
                </Container>
            </div> */}

            <div className="section3-carousel">
                <Container>
                    <Row className='pt-5 justify-content-center'>
                        <Col xs={12} className="text-center heading-full-growth pt-3 pb-3"><h1>DIGIDZN DELIVERS</h1></Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d23}
                                alt="d23"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d24}
                                alt="d24"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d25}
                                alt="d25"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d26}
                                alt="d26"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d27}
                                alt="d27"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d28}
                                alt="d28"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d29}
                                alt="d29"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3' >
                            <img
                                className="d-block img-icon"
                                src={d1}
                                alt="d1"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d2}
                                alt="d2"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d3}
                                alt="d3"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d4}
                                alt="d4"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d5}
                                alt="d5"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d6}
                                alt="d6"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d7}
                                alt="d7"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d8}
                                alt="d8"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d9}
                                alt="d9"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d10}
                                alt="d10"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d11}
                                alt="d11"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d12}
                                alt="d12"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d13}
                                alt="d13"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d14}
                                alt="d14"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d15}
                                alt="d15"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d16}
                                alt="d16"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d17}
                                alt="d17"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d18}
                                alt="d18"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d19}
                                alt="d19"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d20}
                                alt="d20"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d21}
                                alt="d21"
                            />
                        </Col>
                        <Col md={4} xs={12} className='mb-3'>
                            <img
                                className="d-block img-icon"
                                src={d22}
                                alt="d22"
                            />
                        </Col>
                       
                    </Row>
                </Container>
            </div>
            {/* section1 */}
            {/* <div className="mt-10">
                <Container>
                    <Row>
                        <Col md={6}>
                            <img
                                className="d-block  img-result-page"
                                src={Famphy}
                                alt="Famphy"
                            />
                        </Col>
                        <Col md={6} className="result-page-heading">
                            <h1>
                                Famphy: Doctor on Call for non-critical illness
                            </h1>
                            <p>
                                Famphy being a startup had a very sales oriented approach &
                                needed a consistent flow of daily leads & calls to sustain their
                                marketing expenses. What did we achieve? High Organic Traffic,
                                Keyword Rich Content & a lower Customer Acquisition Cost.
                                How did we achieve it? Click on Read More to find out!
                            </p>
                            <Link to='./famphy-doctor-on-call-for-non-critical-illness'>  <button className="growth-button mt-10">READ MORE</button></Link>
                        </Col>

                    </Row>
                </Container>
            </div> */}
            {/* section2 */}
            {/* <div className="mt-10">
                <Container>
                    <Row>
                        <Col md={6}>
                            <img
                                className="d-block  img-result-page"
                                src={Milton}
                                alt="Milton"
                            />
                        </Col>
                        <Col md={6} className="result-page-heading">
                            <h1>
                                Milton Home Appliances: Premium Kitchen Products
                            </h1>
                            <p>
                                Milton Home Appliances is a household name PAN India
                                with thousands of happy customers over the last few decades.
                                Check out our case study on how we managed to penetrate
                                the highly competitive eCommerce market by having an edge over
                                our competitors using just the right strategies!
                            </p>
                            <Link to='./milton-home-appliances-premium-kitchen-products'> <button className="growth-button mt-10">READ MORE</button></Link>
                        </Col>

                    </Row>
                </Container>
            </div> */}
            {/* section3 */}
            {/* <div className="mt-10">
                <Container>
                    <Row>
                        <Col md={6}>
                            <img
                                className="d-block  img-result-page"
                                src={Maven}
                                alt="Maven"
                            />
                        </Col>
                        <Col md={6} className="result-page-heading">
                            <h1>
                                Maven Stainless Steel
                            </h1>
                            <p>
                                Maven stainless is a leading manufacturer of Stainless Steel pipes & tubes
                                having products in every desired shape & size. What did we achieve? High
                                Organic Traffic, Industry Visibility, Repeat orders, Target Audience Engagement.
                                How did we achieve it? Click on Read More to find out!
                            </p>
                            <Link to='./maven-stainless-steel'> <button className="growth-button mt-10">READ MORE</button></Link>
                        </Col>

                    </Row>
                </Container>
            </div> */}
            {/* section4 */}
            {/* <div className="mt-10">
                <Container>
                    <Row>
                        <Col md={6}>
                            <img
                                className="d-block  img-result-page"
                                src={Deco}
                                alt="Deco"
                            />
                        </Col>
                        <Col md={6} className="result-page-heading">
                            <h1>
                                Deco-Arte
                            </h1>
                            <p>
                                Deco-Arte is an interior designing studio that provides complete
                                interior design solutions for restaurants, offices, hotels, homes,
                                and retail spaces. Check out our case study on how we generate leads
                                in a highly competitive market.
                            </p>
                            <Link to='./deco-arte'><button className="growth-button mt-10">READ MORE</button></Link>
                        </Col>

                    </Row>
                </Container>
            </div> */}
            {/* section5  */}
            {/* <div>

            </div>
            <div className="full-growth-bg">
                <Container className="mt-2 ">
                    <Row xs="3">
                        <Col md={4} xs={12}>
                            <div className="full-growthcontent">
                                <h6>
                                    Academy
                                </h6>
                                <p>
                                    Come, learn & intern! digiDZN brings to you courses in
                                    Digital Marketing & eCommerce to make you industry ready.
                                    digiDZN helps you get placed post your training.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} xs={12}>
                            <Link to='./seo' className="text-none-underline">
                                <div className="full-growthcontent">
                                    <h6>
                                        SEO
                                    </h6>
                                    <p>
                                        Do you want to execute modern yet effective
                                        SEO to power your business strategy? Let us
                                        help you to deliver what matters for your industry!
                                    </p>
                                </div>
                            </Link>
                        </Col>


                        <Col md={4} xs={12}>
                            <Link to='./socialmedia' className="text-none-underline">
                                <div className="full-growthcontent">
                                    <h6>
                                        Social Media
                                    </h6>
                                    <p>
                                        Finding new ways to interact and engage with the audience of your brand?
                                        Still not sure how to execute it?
                                        Let us assist you with befitting strategy and approach.
                                    </p>
                                </div>
                            </Link>
                        </Col>


                        <Col md={4} xs={12}>
                            <Link to='./campaigns' className="text-none-underline">
                                <div className="full-growthcontent">
                                    <h6>
                                        Campaigns
                                    </h6>
                                    <p>
                                        Do you know what is essential to get quality leads?
                                        A good campaign strategy! Let us plan some terrific
                                        campaign strategies to make your brand go viral.
                                    </p>
                                </div>
                            </Link>
                        </Col>
                        <Col md={4} xs={12}>
                        <Link to='./campaigns' className="text-none-underline">
                            <div className="full-growthcontent">
                                <h6>
                                    E-Commerce
                                </h6>
                                <p>
                                    Figuring out ways to generate sales on your e-commerce platforms?
                                    Well, we are here to expand your e-commerce
                                    business following all the algorithms and guidelines.
                                </p>
                            </div>
                            </Link>
                        </Col>
                        <Col md={4} xs={12}>
                        <Link to='./pr' className="text-none-underline">
                            <div className="full-growthcontent">
                                <h6>
                                    PR
                                </h6>
                                <p>
                                    Do you know PR strategy is vital for a business
                                    to establish a strong reputation? With the right
                                    PR strategy, brand visibility can be highly effective.
                                </p>
                            </div>
                            </Link>
                        </Col>

                    </Row>
                    <Row>
                        <Col className="text-center mt-5 mb-5">
                            <Link to="./services"> <button className="growth-button">Full Growth Capabilities</button></Link>
                        </Col>
                    </Row>
                </Container>


            </div> */}


            <div>
                <div className='homepage-container1 py-3 py-md-5'>
                    <Container className='py-5 px-5 px-md-2'>
                        <Row>
                            <Col md={12} className="mb-5">
                                <h3 className='f-57 text-white mb-2 mb-md-5 text-center'>Full Growth Capabilities</h3>
                            </Col>
                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to='./seo' className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Search Engine Optimisation – SEO</h3>
                                            <p className='banner-desc'>Get more organic traffic to your website.</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to="./campaigns" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Pay Per Click – PPC</h3>
                                            <p className='banner-desc'>Produce more scales rather than clicks</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>


                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to="#" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Website Design & Development</h3>
                                            <p className='banner-desc'>Design, build and grow.</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to="./social-media" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Social Media Marketing – SMM</h3>
                                            <p className='banner-desc'>Echoing your brand loudly in the virtual marketing ecosystem</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>

                            <Col md={5} className="mb-3 mb-md-5 p-0">
                                <div className='full-section'>
                                    <Link to="./" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">Content Marketing</h3>
                                            <p className='banner-desc'>Define your company and tell the world who you are.</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={5} className="mb-3 mb-md-5p-0">
                                <div className='full-section'>
                                    <Link to="./ecommerce" className='position-relative highlight-circle'>
                                        <img className='mb-5' src={line} />
                                        <div className='content-sections z-index-2 position-relative'>
                                            <h3 class="h4 text-white mb-4">eCommerce Marketing</h3>
                                            <p className='banner-desc'>Innovate, evolve and thrive your commerce.</p>
                                        </div>
                                        <div class="list__circle position-absolute z-index-1 mx-auto d-none d-lg-block acf-block-services-list__circle--small "></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Container>
                </div>
            </div>



            {/* section6-ready to kickoff  http://www.w3.org/2000/svg*/}
            {/* <div class="custom-shape-divider-top-1635236144 mt-10">
                <svg data-name="Layer 1" xmlns="" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>
       <div className="ready-kick-bg">
    
            <Container >
                <Row>
                    <Col className="text-center sec-6-head">
                    <h1>Ready To Kick Off Your Growth Journey</h1>
                    <Link to="./contact-us"><button className="ready-kick-btn mt-5">SPEAK TO DIGIDZN</button></Link>
                    </Col>

                </Row>
            </Container>
        </div> */}
            <div className="ready-kick-bg yellow-bg">

                <Container >
                    <Row>
                        <Col className="text-center sec-6-head mt-4">
                            <h1 className='h4'>Ready To Kick Off Your<br></br> Growth Journey? 🚀</h1>
                            <Link to="./contact-us"> <button className="mt-2 button-custom">SPEAK TO DIGIDZN</button></Link>
                        </Col>

                    </Row>
                </Container>
            </div>
            <Footer />


        </div>
    )
}

export default Results
