import React from 'react'
import './Footer.css'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from '../asset/img/logo.png'
import { FaFacebookSquare, FaInstagram, FaLinkedin, FaTwitterSquare, FaWhatsapp } from 'react-icons/fa'

import Accordion from 'react-bootstrap/Accordion';

function Footer() {
    const phoneNumber = "9289745760"; // Replace with the actual phone number
    const message = encodeURIComponent("Hey I have a query ...."); // Pre-filled message (optional)
    return (
        <div>
            <footer className="footer-digidzn d-none d-md-block">

                <Container>
                    <Row>
                        <Col md={6}>
                            <div className='d-flex justify-content-initial mt-5'>
                                <div className=''>
                                    <Link to='/' className='mr-4'>
                                        <img
                                            className="d-block w-30 h-60 text-center align-items-center"
                                            width="174"
                                            src={logo}
                                            alt="logo"
                                        />
                                    </Link>
                                </div>
                                <div className='text-white mr-25'>
                                    <p>Don’t let your business be lost. Start your digital success story today with our six-year experience and expertise in accelerating brands' growth. Digidzn is the best digital marketing agency in Delhi, India, that you'll find.</p>
                                </div>
                            </div>

                            <div className="address mt-4">
                                <h6 className='text-white bold-text'>Our office</h6>
                                <h6 className='text-white mt-4'>Address - B-28/1, First Floor, Near Seven Seas, Lawrence Road Industrial Area Delhi 110035 <br></br>Phone Number - <a href="tel:9868877018" className='text-white'>9868877018</a> / <a href="tel:9289745760" className='text-white'>9289745760</a></h6>
                                <ul className="inline-icon mt-5 p-0">
                                    <li>
                                        <a href="https://www.facebook.com/digiDZN" className="footer-icon-link text-white" target="_blank"><FaFacebookSquare></FaFacebookSquare></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/digi_dzn/" className="footer-icon-link text-white" target="_blank"><FaInstagram></FaInstagram></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/digidzn/" className="footer-icon-link text-white" target="_blank"><FaLinkedin></FaLinkedin></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/digi_dzn?lang=en" className="footer-icon-link text-white" target="_blank"><FaTwitterSquare></FaTwitterSquare></a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={5}>
                            <Row>
                                <Col md={6} xs={6}>
                                    <div className='mt-5'>
                                        <h6 className='text-white bold-text mb-4'>Company</h6>
                                        <ul className=" footer-menu m-0 p-0">
                                            <li style={{ marginTop: '10px' }}><Link to='/' className='text-white footer-links'>Home</Link></li>


                                            <li><Link to='./about' className='footer-links text-white'>About</Link></li>

                                            <li><Link to='./services' className='footer-links text-white'>Services</Link></li>

                                            <li><Link to='./blog' className='footer-links text-white'>Blogs</Link></li>

                                            <li><Link to='./contact-us' className='footer-links text-white'>Contact Us</Link></li>
                                        </ul>
                                    </div>
                                </Col>

                                <Col md={6} xs={6}>
                                    <div className='mt-5'>
                                        <h6 className='text-white bold-text mb-4'>Services</h6>
                                        <ul className=" footer-menu p-0 m-0">
                                            <li className='footer-links-heading'>Services</li>
                                            <li style={{ marginTop: '10px' }}><Link to='./digitalmarketinginternship' className='footer-links'>D3M Academy</Link></li>


                                            <li><Link to='./seo' className='footer-links'>SEO</Link></li>

                                            <li><Link to='./social-media' className='footer-links text-white'>Social Media</Link></li>

                                            <li><Link to='./campaigns' className='footer-links text-white'>Campaigns</Link></li>

                                            <li><Link to='./ecommerce' className='footer-links text-white'>E-Commerce</Link></li>

                                            <li><Link to='./pr' className='footer-links text-white'>PR</Link></li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>

                <Container fluid>
                    <Row>
                        <Col md={12} lg={12} className="footer-bottom-text text-center">
                            <p className='text-white '>
                                © {(new Date().getFullYear())}. All Rights Reserved.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>

            <footer className="footer-digidzn d-block d-md-none">

                <Container>
                    <Row>
                        <Col md={12} className='px-4'>
                            <div className='d-flex justify-content-initial mt-5 footer_about'>
                                <div className=''>
                                    <Link to='/' className='mr-4'>
                                        <img
                                            className="d-block w-30 h-60 text-center align-items-center"
                                            width="174"
                                            src={logo}
                                            alt="logo"
                                        />
                                    </Link>
                                </div>
                                <div className='text-white mr-25'>
                                    <p>Don’t let your business be lost. Start your digital success story today with our six-year experience and expertise in accelerating brands' growth. Digidzn is the best digital marketing agency in Delhi, India, that you'll find.</p>
                                </div>
                            </div>

                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" className='mb-2 transparent border-nones border-2'>
                                    <Accordion.Header className='transparent'>Our office</Accordion.Header>
                                    <Accordion.Body className='p-0'>
                                        <h6 className='text-white mt-1 line-height-2'>Address - B-28/1, First Floor, Near Seven Seas, Lawrence Road Industrial Area Delhi 110035 <br></br>Phone Number - <a href="tel:9868877018" className='text-white'>9868877018</a> / <a href="tel:9289745760" className='text-white'>9289745760</a></h6>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className='mb-2 transparent border-nones border-2'>
                                    <Accordion.Header className='transparent'>Company</Accordion.Header>
                                    <Accordion.Body className='p-0'>
                                        <ul className=" footer-menu m-0 p-0">
                                            <li style={{ marginTop: '10px' }}><Link to='/' className='text-white footer-links'>Home</Link></li>

                                            <li><Link to='./about' className='footer-links text-white'>About</Link></li>

                                            <li><Link to='./services' className='footer-links text-white'>Services</Link></li>

                                            <li><Link to='./blog' className='footer-links text-white'>Blogs</Link></li>

                                            <li><Link to='./contact-us' className='footer-links text-white'>Contact Us</Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" className='mb-2 mb-4 transparent border-nones border-2'>
                                    <Accordion.Header className='transparent'>Services</Accordion.Header>
                                    <Accordion.Body className='p-0'>
                                        <ul className=" footer-menu p-0 m-0">
                                            <li className='footer-links-heading'>Services</li>
                                            <li style={{ marginTop: '10px' }}><Link to='/digitalmarketinginternship' className='footer-links'>D3M Academy</Link></li>


                                            <li><Link to='./seo' className='footer-links'>SEO</Link></li>

                                            <li><Link to='./social-media' className='footer-links text-white'>Social Media</Link></li>

                                            <li><Link to='./campaigns' className='footer-links text-white'>Campaigns</Link></li>

                                            <li><Link to='./ecommerce' className='footer-links text-white'>E-Commerce</Link></li>

                                            <li><Link to='./pr' className='footer-links text-white'>PR</Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                        </Col>
                    </Row>
                </Container>

                <Container fluid>
                    <Row>
                        <Col md={12} lg={12} className="footer-bottom-text text-center">
                            <ul className="inline-icon mt-0 p-0">
                                <li>
                                    <a href="https://www.facebook.com/digiDZN" className="footer-icon-link text-white px-3" target="_blank"><FaFacebookSquare></FaFacebookSquare></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/digi_dzn/" className="footer-icon-link text-white px-3" target="_blank"><FaInstagram></FaInstagram></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/digidzn/" className="footer-icon-link text-white px-3" target="_blank"><FaLinkedin></FaLinkedin></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/digi_dzn?lang=en" className="footer-icon-link text-white px-3" target="_blank"><FaTwitterSquare></FaTwitterSquare></a>
                                </li>
                            </ul>
                            <p className='text-white '>
                                © {(new Date().getFullYear())}. All Rights Reserved.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>

            <div className='whatsapp'>
                <a
                    href={`https://wa.me/${phoneNumber}?text=${message}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span><FaWhatsapp /></span>  
                </a>
            </div>

        </div >
    )
}

export default Footer
